import * as React from 'react';
import { PageHead } from '@app/components/PageHead';
import { HomeView, HomeViewRedesign } from '@app/views/home';
import { useFunnel } from '@app/contexts/funnel';

const getSplitView = (split: string) => {
  let SplitHomeView = HomeView;

  switch (split) {
    case 'redesign-v2':
      SplitHomeView = HomeViewRedesign;
      break;
    default:
      SplitHomeView = HomeView;
      break;
  }

  return SplitHomeView;
};

const HomePage: React.FC = () => {
  const { funnel } = useFunnel();
  const SplitHomeView = getSplitView(funnel);

  return (
    <>
      <PageHead />
      <SplitHomeView />
    </>
  );
};

export default HomePage;
